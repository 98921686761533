<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('导入物流信息')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
  </el-row>
  <vxe-toolbar id="toolbar" >
    <template v-slot:buttons>
      <el-upload v-if="hasPermission('express:import')" style="display:inline-block"
        class="upload-demo" :with-credentials="true"
        :action="importUrl"
        :show-file-list="false"
        :on-success="handleSuccess">
          <el-button size="mini" icon="el-icon-upload2" 
          style="border-top-right-radius:0px;border-bottom-right-radius:0px;"
          type="primary">{{msg('导入虾皮下载资料入口')}}</el-button>
      </el-upload>
      <el-upload v-if="hasPermission('express:import')"
        class="upload-demo" :with-credentials="true" style="display:inline-block"
        :action="importUrl1"
        :show-file-list="false"
        :on-success="handleSuccess1">
        <el-button size="mini" icon="el-icon-upload2" 
        style="border-top-left-radius:0px;border-bottom-left-radius:0px;"
        type="success">{{msg('导入手动资料')}}</el-button>
      </el-upload>
    </template>   
    <template slot="tools">
      <el-button-group>
        <el-upload v-if="hasPermission('express:import')"
        class="upload-demo" :with-credentials="true" style="display:inline-block"
        :action="importUrl1+'?pagetype=2'"
        :show-file-list="false"
        :on-success="handleSuccess1">
        <el-button size="mini" icon="el-icon-upload2" 
        style="border-top-left-radius:0px;border-bottom-left-radius:0px;"
        type="success">{{msg('导入线下资料')}}</el-button>
      </el-upload>
        <el-button type="success" size="mini" @click="$refs.dataTable.exportData({ type: 'xlsx',filename: '导入结果信息',sheetName: 'Sheet1' })" >
            <i class="el-icon-download" style="display:inline-block;margin-right:4px;"></i>
            <transition name="el-fade-in-linear">
              <span v-show="!isMinScreen">{{msg('下载导入结果')}}</span>
            </transition>
        </el-button>
        <el-button type="primary" size="mini" @click="doDownloadTpl" plain>
          <i class="el-icon-download" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('模板下载')}}</span>
          </transition>
        </el-button>
      </el-button-group>
     </template>
  </vxe-toolbar>
  <div class="table-wrap">
    <vxe-table style="width: 100%" :height="docHeight" ref="dataTable"
        border="inner" size="small"  stripe resizable  :data="exps" 
        show-overflow 
        highlight-hover-row highlight-current-row highlight-hover-column highlight-current-column
      >
        <vxe-table-column type="seq" width="50" ></vxe-table-column>
        <vxe-table-column field="orderCode" :title="msg('订单编号')" min-width="150" :show-overflow="true"></vxe-table-column>
        <vxe-table-column field="errmsg" :title="msg('导入状态')" min-width="150" :show-overflow="true"></vxe-table-column>
        <vxe-table-column field="goodName" :title="msg('品名')" min-width="120" :show-overflow="true"></vxe-table-column>
        <vxe-table-column field="spec" :title="msg('规格')" min-width="120" :show-overflow="true"></vxe-table-column>
        <vxe-table-column field="price" :title="msg('价格')" min-width="80" :show-overflow="true"></vxe-table-column>
        <vxe-table-column field="qty" :title="msg('件数')" min-width="80"></vxe-table-column>
        <vxe-table-column field="expCompany" :title="msg('配送方式')" min-width="80"></vxe-table-column>
        <vxe-table-column field="shopOwner" :title="msg('店铺名')" min-width="120"></vxe-table-column>
        <vxe-table-column field="innerExpCode" :title="msg('国内快递单号')" min-width="120"></vxe-table-column>
        <vxe-table-column field="receiver" :title="msg('收件人')" min-width="120"></vxe-table-column>
        <vxe-table-column field="receiverTel" :title="msg('收件人电话')" min-width="120"></vxe-table-column>
        <vxe-table-column field="receiverAddr" :title="msg('收件人地址')" min-width="180" :show-overflow="true"></vxe-table-column>
        <vxe-table-column field="shopCode" :title="msg('门店号区码')" min-width="120"></vxe-table-column>
        <vxe-table-column field="twExpCode" :title="msg('查询码')" min-width="120"></vxe-table-column>
        <vxe-table-column field="remark" :title="msg('备注')" min-width="120"></vxe-table-column>
      </vxe-table>
  </div>
  <el-row :gutter="10" type="flex" justify="start" align="center" class="ctrl-wrap">
    <el-col :span="24">
        <el-alert :title="msg('exp-import-warning1')"  type="warning"></el-alert>
        <el-alert :title="msg('exp-import-warning2')"  type="warning"></el-alert>
        <el-alert :title="msg('exp-import-warning3') "  type="warning"></el-alert>
        <el-alert :title="msg('exp-import-warning4') "  type="warning"></el-alert>
        <el-alert :title="msg('exp-import-warning5') "  type="warning"></el-alert>
        <el-alert :title="msg('exp-import-warning6') "  type="warning"></el-alert>
    </el-col>
  </el-row>
</div>
</template> 

<script>
// @ is an alias to /src

export default {
  name: 'ExpImport',
  components: {},
  mounted:function(){
    var _this = this;
  },
  data(){
    return{
      importUrl: this.$kit.api.expImport,
      importUrl1: this.$kit.api.doTplImport,
      exps: [],   //表格数据列表
    }
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
        get() {
          return this.$store.state.isMinScreen;
        }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 320;
        }
    }
  },
  methods: {
    handleSuccess(res, file, fileList) {
        if(res.status){
          this.exps = res.data;
        }else{
          this.$message(res.msg || this.msg("上传失败"));
        }
    },
    handleSuccess1(res, file, fileList) {
        if(res.status){
          this.exps = res.data;
        }else{
          this.$message(res.msg || this.msg("上传失败"));
        }
    },
    downloadTpl(command){
      if(command== '0'){
        window.location.href= this.$kit.api.tplDownload;
      }
      // else if(command==1){
      //   window.location.href= this.$kit.api.tplStoreDownload;
      // }
    },
    doDownloadTpl(){
      window.location.href= this.$kit.api.tplDownload;
    }
  }
}
</script>

<style class="scss" scoped>


</style>
